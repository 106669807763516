<template>
  <div class="pa-10">


    <title-page icon="ico-carplug">
      {{ $tc('price-tariff.price-tariff', 2) }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $tc('price-index.price-index', 2), to: {name: 'price-index'}, exact: true},
            {text: priceIndex?.name},
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row v-if="currentUser.permissions.includes('OPERATOR_WRITE')">
      <v-col class="text-right">
        <v-btn :to="{name: 'price-tariff.add'}">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('price-tariff.add')}}
        </v-btn>
      </v-col>
    </v-row>


    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="cp-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            :options.sync="options"
            :server-items-length="total"
        >
          <template #item.period="{value}">
            {{ value|formatDate($t('format_date')) }}
          </template>
          <template #item.value="{value}">
            {{ value }}
            </template>

          <template #item.options="{item}">
            <v-btn icon :to="{name: 'price-tariff.edit', params: {id: item.id}}">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>






  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import ExportDefault from "@blocks/ExportDefault.vue";
import PriceIndexRepository from "@repository/PriceIndexRepository";
import PriceTariffRepository from "@repository/PriceTariffRepository";

export default {
  components: {

  },
  data() {
    return {
      priceIndex: null,
      loading: false,
      items: [],
      total: 0,
      options: {
        sortBy: ['period'],
        sortDesc: [true]
      },

    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.loadPriceIndex()
  },

  watch: {


    options() {
      this.searchData()
    }
  },

  computed: {
    headers() {
      return [
        {text: this.$t('date'), value: 'period'},
        {text: this.$t('index'), value: 'value'},
        {text: '', value: 'options'},
      ]
    },

    priceIndexId() {
      return this.$route.params.priceIndexId
    },
  },

  methods: {

    loadPriceIndex() {
      PriceIndexRepository.get(this.priceIndexId).then(result => {
        this.priceIndex = result
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },


    searchData() {

      let options = this.options


      this.loading = true
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let orderBy = options.sortBy.length > 0 ? options.sortBy[0] : null
      let orderDirection = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'




      this.loading = true
      PriceTariffRepository.search(
          this.priceIndexId,
          offset,
          limit,
          orderBy,
          orderDirection,
      ).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },

  }
}
</script>

<style lang="scss">



</style>
