<template>
  <div class="price-tariff pa-10">



    <title-page icon="ico-price-list" class="mb-0">
      {{ $t("price-tariff.add") }}
    </title-page>

    <v-breadcrumbs
        :items="[
            {text: $tc('price-index.price-index', 2), to: {name: 'price-index'}, exact: true},
            {text: priceIndex?.name, to: {name: 'price-tariff'}, exact: true},
            {text: $tc( isEdit ? 'price-tariff.edit' : 'price-tariff.add', 2)},
        ]"
        divider=">"
    ></v-breadcrumbs>


    <div class="mt-5">
      <headline>{{ $tc('price-tariff.price-tariff') }}</headline>
      <v-row>
        <v-col cols="12">
          <v-form ref="form" @submit.prevent="save">
            <v-row>
              <v-col cols="12" class="text-right">
                <form-field
                    type="date"
                    :label="$t('date')"
                    v-model="form.period"
                    :rules="[
                        commonRules.required,
                    ]"
                    col-field="9"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"  class="text-right">
                <form-field
                    input-type="number"
                    :label="$t('index')"
                    v-model.number="form.value"
                    :rules="[
                        commonRules.required,
                    ]"
                    col-field="9"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="text-center">
                <v-btn type="submit" color="primary">
                  {{ $t('save') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-form>

          <v-divider class="my-10"></v-divider>

          <v-row>
            <v-col class="text-center">
              <v-btn small @click="deleteItem">
                {{ $t('delete') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

    </div>

  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import RulesMixin from "@mixins/RulesMixin";
import PriceTariffRepository from "@repository/PriceTariffRepository";
import PriceIndexRepository from "@repository/PriceIndexRepository";

export default {
  components: {

  },
  data() {
    return {
      priceIndex: null,
      form: {
        name: null
      },
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.loadPriceIndex()
    if(this.isEdit) {
      this.loadData()
    }

  },

  watch: {
    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },

  computed: {
    id() {
      return this.$route.params.id ?? null
    },
    priceIndexId() {
      return this.$route.params.priceIndexId ?? null
    },

    isEdit() {
      return !!this.id
    }
  },

  methods: {

    loadPriceIndex() {
      PriceIndexRepository.get(this.priceIndexId).then(result => {
        this.priceIndex = result
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },


    cancel() {

    },


    loadData() {
      PriceTariffRepository.get(this.id).then(result => {
        this.form = result
        this.$nextTick(() => {
          this.cleanUnsavedChanges()
        })
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    save() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)

        if(this.isEdit) {
          PriceTariffRepository.update(this.id, this.form).then(response => {
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$dialog.notify.success(this.$t('update-success'))
            this.$router.push({name: 'price-tariff'})
          }).catch(err => {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
          })
        } else {
          PriceTariffRepository.create(this.priceIndexId, this.form).then(response => {
            this.showLoading(false)
            this.cleanUnsavedChanges()
            this.$dialog.notify.success(this.$t('created-successfully'))
            this.$router.push({name: 'price-tariff'})
          }).catch(err => {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
          })
        }

      }

    },


    deleteItem() {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          PriceTariffRepository.delete(this.id).then(result => {
            this.showLoading(false)
            this.$dialog.notify.success(this.$t('delete-success'))
            this.$router.replace({name: 'price-tariff'})
          }).catch(err => {
            this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
            this.showLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.price-tariff {

}
</style>
